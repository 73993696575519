<template>
  <div id="app" safe-area-inset-bottom>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
// import { } from 'vuex'
export default {
  components: {},
  props: [],
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {
    document.title = '祥龙博瑞-飞行端';
  },
  mounted() {},
  methods: {
  }

};
</script>
<style lang="scss">
@import './assets/style/common.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 28px;
}

</style>
<style lang="less">

//@import './assets/style/vant.less';
</style>
