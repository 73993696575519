
export function resetTokenAndClearUser() {
  // 退出登陆 清除用户资料
  localStorage.setItem('token', '');
  localStorage.setItem('userImg', '');
  localStorage.setItem('userName', '');
  // 重设路由
}

export const defaultDocumentTitle = '祥龙博瑞-供应商管理系统';
export function getDocumentTitle(pageTitle) {
  if (pageTitle) return `${defaultDocumentTitle} - ${pageTitle}`;
  return `${defaultDocumentTitle}`;
}

export function randomString() {
  const len = 20;
  const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  const maxPos = $chars.length;
  let pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}
