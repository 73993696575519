import Vue from 'vue';
import Vuex from 'vuex';
import userInfo from './modules/userInfo';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    token: state => state.userInfo.token,
    userInfo: state => state.userInfo.userInfo,
  },
  modules: {
    userInfo
  }
});
